@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body,
  #__next {
    height: 100vh;
    min-height: 100vh;
    @apply bg-slate-100;
  }
}

/* Modern Stats Table Styling */
.player-stats-table table {
  font-family: "Manrope", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    sans-serif;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 0.7rem;
}

.player-stats-table table thead tr th {
  background-color: #f5f5f5;
  color: #666;
  font-weight: 500;
  padding: 0.2rem 0.2rem;
  border-bottom: 1px solid #e0e0e0;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  text-align: left;
  transition: background-color 0.2s;
}

@media (min-width: 640px) {
  .player-stats-table table thead tr th {
    padding: 0.4rem 0.5rem;
  }
}

.player-stats-table table thead tr th:hover {
  background-color: #eeeeee;
}

.player-stats-table table tbody tr {
  transition: background-color 0.15s;
}

.player-stats-table table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.player-stats-table table tbody tr td {
  padding: 0.2rem 0.2rem;
  border-bottom: 1px solid #f0f0f0;
}

@media (min-width: 640px) {
  .player-stats-table table tbody tr td {
    padding: 0.4rem 0.5rem;
  }
}

.player-stats-table table tbody tr:last-child td {
  border-bottom: none;
}

/* Stats ultra-compact variant for mobile-optimized view */
.stats-ultra-compact table {
  font-family: "Manrope", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    sans-serif;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 0.65rem;
}

.stats-ultra-compact table thead tr th {
  padding: 0.1rem 0;
  font-size: 0.65rem;
  line-height: 1;
}

.stats-ultra-compact table tbody tr td {
  padding: 0 0;
  font-size: 0.65rem;
  line-height: 1;
}

@media (min-width: 640px) {
  .stats-ultra-compact table thead tr th {
    padding: 0.1rem 0.1rem;
  }

  .stats-ultra-compact table tbody tr td {
    padding: 0 0.1rem;
  }
}

/* Stats modern variant */
.stats-modern table {
  font-family: "Manrope", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    sans-serif;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 0.7rem;
}

.stats-modern table thead tr th {
  background-color: #f5f5f5;
  color: #666;
  font-weight: 500;
  padding: 0.4rem 0.5rem;
  border-bottom: 1px solid #e0e0e0;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  text-align: left;
  transition: background-color 0.2s;
}

.stats-modern table thead tr th:hover {
  background-color: #eeeeee;
}

.stats-modern table tbody tr {
  transition: background-color 0.15s;
}

.stats-modern table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.stats-modern table tbody tr td {
  padding: 0.4rem 0.5rem;
  border-bottom: 1px solid #f0f0f0;
}

.stats-modern table tbody tr:last-child td {
  border-bottom: none;
}

/* Stats compact variant for dense information display */
.stats-compact table {
  font-family: "Manrope", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    sans-serif;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 0.6rem; /* Smaller font size than standard */
}

.stats-compact table thead tr th {
  background-color: #f5f5f5;
  color: #666;
  font-weight: 500;
  padding: 0.25rem 0.35rem; /* Reduced padding */
  border-bottom: 1px solid #e0e0e0;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  text-align: left;
  transition: background-color 0.2s;
}

.stats-compact table thead tr th:hover {
  background-color: #eeeeee;
}

.stats-compact table tbody tr {
  transition: background-color 0.15s;
}

.stats-compact table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.stats-compact table tbody tr td {
  padding: 0.25rem 0.35rem; /* Reduced padding */
  border-bottom: 1px solid #f0f0f0;
}

.stats-compact table tbody tr:last-child td {
  border-bottom: none;
}

/* Score Ticker Live Status Animations */
.status-live,
.status-break {
  display: inline-block;
  color: green;
  animation: liveUnderline 2s 2s infinite;
  text-decoration: none;
  background-image: linear-gradient(
      transparent 2px,
      #2ed392 2px,
      #2ed392 4px,
      transparent 4px
    ),
    linear-gradient(
      transparent 2px,
      transparent 2px,
      transparent 4px,
      transparent 4px
    );
  background-size: 0% 6px, 100% 6px;
  background-position: bottom;
  background-repeat: no-repeat;
  padding-bottom: 4px;
  border-bottom: 6px solid transparent;
  margin-bottom: -9px;
  margin-top: 0px;
}

@keyframes liveUnderline {
  from {
    background-position: bottom 0 left 0;
    background-size: 0% 6px;
  }
  50% {
    background-size: 100% 6px;
  }
  to {
    background-position: bottom 0 left 0;
    background-size: 0% 6px;
  }
}

/* Mobile menu login/account styles */
.mobile-login-status button {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: background 0.2s;
}

.mobile-login-status button:hover {
  background: rgba(255, 255, 255, 0.3);
}

.mobile-login-status .text-gray-500,
.mobile-login-status .text-gray-700,
.mobile-login-status .text-gray-800,
.mobile-login-status .text-gray-900 {
  color: #ffffff !important;
}

.mobile-login-status .bg-white {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.mobile-login-status .border-gray-100,
.mobile-login-status .border-gray-200 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

/* Status Modern variant */
.status-modern {
  display: inline-block;
  color: green;
  animation: liveUnderline 2s 2s infinite;
  text-decoration: none;
  background-image: linear-gradient(
      transparent 2px,
      #2ed392 2px,
      #2ed392 4px,
      transparent 4px
    ),
    linear-gradient(
      transparent 2px,
      transparent 2px,
      transparent 4px,
      transparent 4px
    );
  background-size: 0% 6px, 100% 6px;
  background-position: bottom;
  background-repeat: no-repeat;
  padding-bottom: 4px;
  border-bottom: 6px solid transparent;
  margin-bottom: -9px;
  margin-top: 0px;
}

@keyframes liveUnderline {
  from {
    background-position: bottom 0 left 0;
    background-size: 0% 6px;
  }
  50% {
    background-size: 100% 6px;
  }
  to {
    background-position: bottom 0 left 0;
    background-size: 0% 6px;
  }
}
